import { useState } from "react";
import { Link } from "react-router-dom";

function RegistrationForm(props) {
  const [formdata, setFormdata] = useState({
    teamName: "",
    school: "",
    teacherInChargeName: "",
    phoneNumberOfInCharge: "",
    teamLeaderName: "",
    leaderEmail: "",
    leaderAddress: "",
    leaderPhoneNumber: "",
    leaderNationalID: "",
    member1Name: "",
    member1Email: "",
    member1Address: "",
    member1PhoneNumber: "",
    member1NationalID: "",
    member2Name: "",
    member2Email: "",
    member2Address: "",
    member2PhoneNumber: "",
    member2NationalID: "",
    member3Name: "",
    member3Email: "",
    member3Address: "",
    member3PhoneNumber: "",
    member3NationalID: "",
  });

  const [formSubmit, setFormSubmit] = useState(false);

  const handleForm = (e) => {
    if (e.target.id.includes("Number") || e.target.id.includes("NationalID")) {
      var regex = /^(?:|\d+)$/;
      if (!regex.test(e.target.value)) {
        return;
      }
    }
    setFormdata({ ...formdata, [e.target.name]: e.target.value });
    document.getElementById(e.target.id).style.borderColor = "#ccc";
    //console.log(formdata);
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const formValidation = () => {
    let flag = true;
    Object.keys(formdata).forEach((key) => {
      const value = formdata[key];
      if (value === "") {
        document.getElementById(key).style.borderColor = "#ff0000";
        flag = false;
      }
      if (key.includes("Email")) {
        if (!validateEmail(value)) {
          document.getElementById(key).style.borderColor = "#ff0000";
          flag = false;
        }
      }
      if (key.includes("Number")) {
        if (value.length != 10) {
          document.getElementById(key).style.borderColor = "#ff0000";
          flag = false;
        }
      }
      if (key.includes("NationalID")) {
        if (value.length != 12) {
          document.getElementById(key).style.borderColor = "#ff0000";
          flag = false;
        }
      }
      //console.log(`Key: ${key}, Value: ${value}`);
    });

    return flag;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let flag = formValidation();
    if (flag == false) {
      return;
    }
    if (formSubmit == true)
    {
      return;
    }
    const response = await fetch(props.link, {
      method: "POST",
      body: JSON.stringify(formdata),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await response.json();
    //console.log(result);
    if (response.ok){
      setFormSubmit(true);
      console.log("okay");
    }
  };

  return (
    <>
      <nav
        id="menu"
        className="navbar navbar-default navbar-fixed-top"
        style={{ height: "80px" }}
      >
        <div className="container text-center">
          <div
            className="navbar-header"
            style={{
              position: "absolute",
              left: "50%",
              transform: "translate(-50%, -0)",
            }}
          >
            <Link className="navbar-brand page-scroll" to="/">
              <img
                src={props.data && props.data.logo}
                alt=""
                className="nav-logo"
              />
            </Link>{" "}
          </div>
        </div>
      </nav>

      <div className="container" style={{ marginTop: "100px" }}>
        <form>
          <h3 className="text-center">Details Of Team</h3>

          <div className="col-md-6">
            <div style={{ marginBottom: "3rem" }}>
              <label htmlFor="teamName" className="form-label">
                Team Name
              </label>
              <input
                type="name"
                name="teamName"
                className="form-control warning"
                id="teamName"
                value={formdata.teamName}
                onChange={handleForm}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div style={{ marginBottom: "3rem" }}>
              <label htmlFor="school" className="form-label">
                School
              </label>
              <input
                type="name"
                name="school"
                className="form-control"
                id="school"
                value={formdata.school}
                onChange={handleForm}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div style={{ marginBottom: "3rem" }}>
              <label htmlFor="teacherInChargeName" className="form-label">
                Name Of Teacher In Charge
              </label>
              <input
                type="name"
                name="teacherInChargeName"
                className="form-control"
                id="teacherInChargeName"
                value={formdata.teacherInChargeName}
                onChange={handleForm}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div style={{ marginBottom: "3rem" }}>
              <label htmlFor="phoneNumberOfInCharge" className="form-label">
                Phone Number Of Teacher In Charge
              </label>
              <input
                type="text"
                name="phoneNumberOfInCharge"
                className="form-control"
                id="phoneNumberOfInCharge"
                value={formdata.phoneNumberOfInCharge}
                onChange={handleForm}
              />
            </div>
          </div>

          <h3 style={{ paddingTop: "20px" }} className="text-center col-md-12">
            Details Of Team Leader
          </h3>

          <div className="col-md-6">
            <div style={{ marginBottom: "3rem" }}>
              <label htmlFor="teamLeaderName" className="form-label">
                Full Name
              </label>
              <input
                type="name"
                name="teamLeaderName"
                className="form-control"
                id="teamLeaderName"
                value={formdata.teamLeaderName}
                onChange={handleForm}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div style={{ marginBottom: "3rem" }}>
              <label htmlFor="leaderEmail" className="form-label">
                Email
              </label>
              <input
                type="email"
                name="leaderEmail"
                className="form-control"
                id="leaderEmail"
                value={formdata.leaderEmail}
                onChange={handleForm}
              />
            </div>
          </div>

          <div className="col-md-12">
            <div style={{ marginBottom: "3rem" }}>
              <label htmlFor="leaderAddress" className="form-label">
                Address
              </label>
              <input
                type="name"
                name="leaderAddress"
                className="form-control"
                id="leaderAddress"
                value={formdata.leaderAddress}
                onChange={handleForm}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div style={{ marginBottom: "3rem" }}>
              <label htmlFor="leaderPhoneNumber" className="form-label">
                Phone Number(Whatsapp)
              </label>
              <input
                type="text"
                name="leaderPhoneNumber"
                className="form-control"
                id="leaderPhoneNumber"
                value={formdata.leaderPhoneNumber}
                onChange={handleForm}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div style={{ marginBottom: "3rem" }}>
              <label htmlFor="leaderNationalID" className="form-label">
                NIC Number
              </label>
              <input
                type="name"
                name="leaderNationalID"
                className="form-control"
                id="leaderNationalID"
                value={formdata.leaderNationalID}
                onChange={handleForm}
              />
            </div>
          </div>

          <h3 style={{ paddingTop: "20px" }} className="text-center col-md-12">
            Details Of Member 1
          </h3>

          <div className="col-md-6">
            <div style={{ marginBottom: "3rem" }}>
              <label htmlFor="member1Name" className="form-label">
                Full Name
              </label>
              <input
                type="name"
                name="member1Name"
                className="form-control"
                id="member1Name"
                value={formdata.member1Name}
                onChange={handleForm}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div style={{ marginBottom: "3rem" }}>
              <label htmlFor="member1Email" className="form-label">
                Email
              </label>
              <input
                type="email"
                name="member1Email"
                className="form-control"
                id="member1Email"
                value={formdata.member1Email}
                onChange={handleForm}
              />
            </div>
          </div>

          <div className="col-md-12">
            <div style={{ marginBottom: "3rem" }}>
              <label htmlFor="member1Address" className="form-label">
                Address
              </label>
              <input
                type="name"
                name="member1Address"
                className="form-control"
                id="member1Address"
                value={formdata.member1Address}
                onChange={handleForm}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div style={{ marginBottom: "3rem" }}>
              <label htmlFor="member1PhoneNumber" className="form-label">
                Phone Number(Whatsapp)
              </label>
              <input
                type="text"
                name="member1PhoneNumber"
                className="form-control"
                id="member1PhoneNumber"
                value={formdata.member1PhoneNumber}
                onChange={handleForm}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div style={{ marginBottom: "3rem" }}>
              <label htmlFor="member1NationalID" className="form-label">
                NIC Number
              </label>
              <input
                type="name"
                name="member1NationalID"
                className="form-control"
                id="member1NationalID"
                value={formdata.member1NationalID}
                onChange={handleForm}
              />
            </div>
          </div>

          <h3 style={{ paddingTop: "20px" }} className="text-center col-md-12">
            Details Of Member 2
          </h3>

          <div className="col-md-6">
            <div style={{ marginBottom: "3rem" }}>
              <label htmlFor="member2Name" className="form-label">
                Full Name
              </label>
              <input
                type="name"
                name="member2Name"
                className="form-control"
                id="member2Name"
                value={formdata.member2Name}
                onChange={handleForm}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div style={{ marginBottom: "3rem" }}>
              <label htmlFor="member2Email" className="form-label">
                Email
              </label>
              <input
                type="email"
                name="member2Email"
                className="form-control"
                id="member2Email"
                value={formdata.member2Email}
                onChange={handleForm}
              />
            </div>
          </div>

          <div className="col-md-12">
            <div style={{ marginBottom: "3rem" }}>
              <label htmlFor="member2Address" className="form-label">
                Address
              </label>
              <input
                type="name"
                name="member2Address"
                className="form-control"
                id="member2Address"
                value={formdata.member2Address}
                onChange={handleForm}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div style={{ marginBottom: "3rem" }}>
              <label htmlFor="member2PhoneNumber" className="form-label">
                Phone Number(Whatsapp)
              </label>
              <input
                type="text"
                name="member2PhoneNumber"
                className="form-control"
                id="member2PhoneNumber"
                value={formdata.member2PhoneNumber}
                onChange={handleForm}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div style={{ marginBottom: "3rem" }}>
              <label htmlFor="member2NationalID" className="form-label">
                NIC Number
              </label>
              <input
                type="name"
                name="member2NationalID"
                className="form-control"
                id="member2NationalID"
                value={formdata.member2NationalID}
                onChange={handleForm}
              />
            </div>
          </div>

          <h3 style={{ paddingTop: "20px" }} className="text-center col-md-12">
            Details Of Member 3
          </h3>

          <div className="col-md-6">
            <div style={{ marginBottom: "3rem" }}>
              <label htmlFor="member3Name" className="form-label">
                Full Name
              </label>
              <input
                type="name"
                name="member3Name"
                className="form-control"
                id="member3Name"
                value={formdata.member3Name}
                onChange={handleForm}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div style={{ marginBottom: "3rem" }}>
              <label htmlFor="member3Email" className="form-label">
                Email
              </label>
              <input
                type="email"
                name="member3Email"
                className="form-control"
                id="member3Email"
                value={formdata.member3Email}
                onChange={handleForm}
              />
            </div>
          </div>

          <div className="col-md-12">
            <div style={{ marginBottom: "3rem" }}>
              <label htmlFor="member3Address" className="form-label">
                Address
              </label>
              <input
                type="name"
                name="member3Address"
                className="form-control"
                id="member3Address"
                value={formdata.member3Address}
                onChange={handleForm}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div style={{ marginBottom: "3rem" }}>
              <label htmlFor="member3PhoneNumber" className="form-label">
                Phone Number(Whatsapp)
              </label>
              <input
                type="text"
                name="member3PhoneNumber"
                className="form-control"
                id="member3PhoneNumber"
                value={formdata.member3PhoneNumber}
                onChange={handleForm}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div style={{ marginBottom: "3rem" }}>
              <label htmlFor="member3NationalID" className="form-label">
                NIC Number
              </label>
              <input
                type="name"
                name="member3NationalID"
                className="form-control"
                id="member3NationalID"
                value={formdata.member3NationalID}
                onChange={handleForm}
              />
            </div>
          </div>

          <div className="col text-center">
            <button
              style={{ marginTop: "20px" }}
              className="btn btn-custom btn-lg page-scroll"
              onClick={handleSubmit}
            >
              {formSubmit ? "registration success" : "register"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default RegistrationForm;
