function Workshop(props) {
  return (
    <a href={props.ytlink} target="blank">
    <div className="row" style={{width: "100%", border: "1px solid grey", borderRadius: "3rem", margin: "0px"}}>
      <div className="col-sm-6 col-6" style={{padding: "2rem", display:"flex", justifyContent: "center", alignItems: "center", height: "200px"}}>
        <div>
        <h3 style={{fontSize: "3rem"}}>{props.title}</h3>
        <p>{props.desc}</p>
        <p>{props.date}</p></div>
      </div>
      <div className="col-sm-6 col-6" style={{margin: "2rem 0",padding: "2rem", display:"flex", justifyContent: "center", alignItems: "center", height: "200px"}}>
        <img style={{width: "100%", maxWidth: "430px", maxHeight: "200px" , borderRadius: "2rem"}} src={props.img} alt="" />
      </div>
    </div>
    </a>
  );
}

function Workshops() {
  return (
    <div id="workshops" className="container  section-title" style={{paddingTop: "80px"}}>
        <h2 style={{textAlign: "center", marginBottom: "3rem"}}>Workshops</h2>
      <div className="row">
        <div className="col-sm-6" style={{marginTop: "2rem"}}>
            <Workshop title="Workshop 01" date="04 May 2024" desc="Conducted by Mr. Dilumika Navodya" img="img/workshops/Thumbnail1.png" ytlink="https://youtu.be/UbRrz1PUCi4"/>
        
        </div>
        <div className="col-sm-6" style={{marginTop: "2rem"}}>
        <Workshop title="Workshop 02" date="16 May 2024" desc="Conducted by Mr. Lahiru P Fernando" img="img/workshops/Thumbnail2.png" ytlink="https://youtu.be/38mUvaT7pNM"/>
        </div>
      </div>
    </div>
  );
}

export default Workshops;
