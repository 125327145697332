import { Link } from "react-router-dom";

function PageNotFound(props) {
    return ( 
        <>
      <nav
        id="menu"
        className="navbar navbar-default navbar-fixed-top"
        style={{ height: "80px" }}
      >
        <div className="container text-center">
          <div
            className="navbar-header"
            style={{
              position: "absolute",
              left: "50%",
              transform: "translate(-50%, -0)",
            }}
          >
            <Link className="navbar-brand page-scroll" to="/">
              <img
                src={props.data && props.data.logo}
                alt=""
                className="nav-logo"
              />
            </Link>{" "}
          </div>
        </div>
        <div className="text-center" style={{marginTop: "300px"}}><h1 className="display-01">404 Page Not Found</h1></div>
      </nav>
    </>
     );
}

export default PageNotFound;